/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://s6x725t6vbhkxg65szycxqxwnq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-ptvg5s5bi5hiznafcr2a3zsaii",
    "aws_cognito_identity_pool_id": "us-east-1:c99d484b-769f-4ed2-b4e6-4091d75304f4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_BW5ktUenI",
    "aws_user_pools_web_client_id": "g21ppvpv0dulei5k0lfrm60st",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
